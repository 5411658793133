.parameters>div {
    align-content: center;
    display: flex;
    flex-direction: column;
}

.parameters {
    background-color: #e7e7e7;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 10px;
    font-size: 18px;
}

.parameters-other {
    background-color: white;
    margin-top: 10px;
    margin-bottom: 2px;
    padding: 8px;
}

.parameters-gradient {
    background-color: white;
    margin-top: 10px;
    margin-bottom: 2px;
    padding: 8px;
}

.parameters-lang {
    background-color: white;
    margin-top: 10px;
    margin-bottom: 2px;
    padding: 8px;
}

#parameters-lang {
    font-size: 17px;
    margin-top: 8px;
    margin-bottom: 2px;
    height: 27px;
    width: 70px;
    
}

.parameter-name {
    position: relative;
    margin-left: 3px;
    top: 1px;
}


.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 8px;
    margin-top: 8px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  // https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 2px;
    height: 23px;
    width: 23px;
    background-color: #e7e7e7ee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 8.2px;
    left: 8.5px;
    width: 6.5px;
    height: 6.5px;
    border-radius: 50%;
    background: white;
  }