// Font

* {
    font-family: Arial, Helvetica, sans-serif;
    // font-family: "Poppins", sans-serif;
    list-style: none;
}

// Background

body {
    background-color: #ffffff;
}